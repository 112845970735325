export default class Constante {
    // url_base = 'http://192.168.1.20:8080/lirida/api/';
    url_base = 'https://pallua.com.br/lirida_novo/api/';
    token = '91cbc49b-e0ef-4180-a0e5-8158abf36ab0';

    Telas = {
        Hotsite: 'sis-hs',
        DigitacaoRapida: 'sis-dr',
        Documento: 'sis-docs',
        // Pessoa: 'sis-pes',
        Financeiro: 'sis-fi',
        Execucao: 'sis-exe',
        MapaDocumento: 'sis-mpdocs',
        Cadastro: 'sis-cad',
        Iexcombo: 'sis-iex'
    }

    /* PERFIL PERFIL */
    seqPessoaPerfilCliente = 'bfcbf889-92e6-4203-8dcb-9536c09ae496';
    seqPessoaPerfilTecnico = '05d281ba-e7c1-4d1a-9ab7-e4118bd336d1';
    seqPessoaPerfilFornecedor = '5f429267-3202-4dac-8219-c38ab14121da';
    seqPessoaPerfilFabricante = '0d438e9e-575a-4771-b6ee-94c893974e7b';
    seqPessoaPerfilTecnicoInteressado = '039552a4-f6f9-48af-8c86-f6b7eac30dea';
    seqPessoaPerfilOperacional = '580cea1c-4d0b-455f-abd6-881370a3c111';
    seqPessoaPerfilEmpresaParceira = 'eb96c7e2-3fc8-4d5e-82a5-55d86eaf690c';
    seqPessoaPerfilRoboTotem = '3c87613a-0322-4fdb-9425-3343fe0335a4';
    seqPessoaPerfilRoboPDVSite = '687d3a36-6944-4284-bc7b-692c54f6a904';
    seqPessoaPerfilDependenteAIG = '1f4eaf75-37e8-4c6c-967c-103e1d84342f';

    /* PESSOA CARACTERISTICA */
    seqPessoaCaracteristicaBase = '1a0bd8b9-c954-416b-8a1a-a64c673a88ac';
    seqPessoaCaracteristicaIexcombo = 'd2de7156-12e5-43d3-bff9-917f82c1d1c6';

    /* USUARIO */
    seqUsuario = 'bce9f0e2-7dc8-4429-a130-46100707356e';

    /* TABELA PREÇO */
    seqTabelaPrecoTotem = '085f4583-c330-4c10-9058-d184c96071ef';
    seqTabelaPrecoVendas = '938f3656-3f7f-44bc-aa88-3607552da07c';

    /* TIPO RELACIONAMENTO */
    seqTipoRelacionamentoFabricante = '79224fa3-53a4-4231-8d9e-e9ec812ac3df';
    seqTipoRelacionamentoCliente = 'af729e07-16dd-4753-b686-21c2b3ada6b0';
    seqTipoRelacionamentoTecnicoRejeitado = '59f0df28-447d-4a78-972f-e31bfb1d8b11';
    seqTipoRelacionamentoTecnicoAgendado = 'a929318e-136c-4573-9ce8-372f5188ca82';
    seqTipoRelacionamentoTecnicoResponsavel = 'c752aa3c-98fd-466b-a7d0-9f71a9fa9a86';
    seqTipoRelacionamentoSolicitante = 'becc8f4d-57cb-4b00-a57c-c0669d5de85b';
    seqTipoRelacionamentoDependenteIexcombo = '59289ace-6ec9-431a-a186-74085b97ec9c';
    seqTipoRelacionamentoBase = 'ecf93dd3-5b33-41df-803d-d4f21529a8f8';

    /* FORMA PAGAMENTO */
    seqFormaPagamentoPix = '3748d622-af97-41f2-871b-abb6075b1a34';
    seqFormaPagamentoCartaoCredito = 'e0c37ea2-0d6b-406f-abfe-e9487a21425e';
    seqFormaPagamentoBoleto = '7aee8450-daba-4241-a7ef-92a7fcb12048';

    /* CONDIÇÃO PAGAMENTO FP */
    seqCondicaoPagamentoFpCartaoCredito = 'c203fa1d-ef4a-4997-ac68-c64909dc4de5';

    /* DOCUMENTO ITEM CARACTERÍSTICA */
    seqDocumentoItemCaracteristicaInstalacao = 'f9a36252-03c6-4d3b-966f-0d23bcf2a7c4';
    seqDocumentoItemCaracteristicaManutencao = '0ec0c01e-7c83-466c-bf13-5cae7d6dc113';

    /* BANCO */
    seqContaBancaria = '0ff50fe7-d1ef-476a-9a1e-f32635ae0c94';

    /* FINANCEIRO */
    seqContaFinanceiraReceber = 'b393b7ee-cab9-4ee2-92f7-e65c9c0918fe';
    seqContaFinanceiraPagar = '0520776a-3a36-4869-81d8-09eca0f139cd';

    /* TIPO ENDEREÇO */
    seqTipoEnderecoEntrega = '7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09';
    seqTipoEnderecoCobranca = 'b4062983-6166-4c91-8319-a25287b505b2';

    /* ITEM CATEGORIA CARACTERÍSTICA */
    seqItemCategoriaCaracteristaItemTotem = '7b9dfec9-5ef0-4045-979f-cab45bff5376';

    /* TIPO CONTATO */
    seqTipoContatoCelular = '215e44df-7fb0-46ba-81ea-fc47984aa7ac';
    seqTipoContatoWhatsApp = '40c9d9fa-df1f-49f1-8f90-59dc2001f093';
    seqTipoContatoEmail = '787b95b2-d72b-46b4-8f94-9ec19f58b117';

    /* TIPO DOCUMENTO */
    seqTipoDocumento = '2406bdcd-0fc1-42e2-864f-ae59e982aeee';

    /* TIPO DOCUMENTO PROCESSO */
    seqTipoDocumentoProcessoPrimeiroAgendamento = '808943d3-db64-46e4-8c4a-68e273163fd9';
    seqTipoDocumentoProcessoAgendada = '3937038f-fcf8-4aef-9e1f-60c8f4c0f2c9';
    seqTipoDocumentoProcessoAguardandoPagamento = '809ddc7b-9a6b-4f84-99d1-5fa5a246a46f';
    seqTipoDocumentoProcessoTenicoRejeitado = 'a03c20b7-1577-41f6-bf6c-ca042fcfebf8';
    seqTipoDocumentoProcessoFinalizado = '34ce8d97-10e0-4d62-a62e-26ad6778727f';

    /* ID */
    idListaContaBancaria = 'CB';
    idListaContaFinanceira = 'CF';
    idListaFormaPagamento = 'FP';

    /* STATUS */
    statusServicoNaoPago = -1;
    statusServicoDisponivel = 0;
    statusServicoAgendado = 1;
    statusServicoRejeitado = 2;
    statusServicoFinalizado = 3;
}