import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import Financeiro from 'lirida-back-service/Servicos/Financeiro/Financeiro';
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import { Component } from 'react';
import TipoDocumentoService from 'lirida-back-service/Servicos/TipoDocumento/TipoDocumentoService';
import CondicaoPagamentoService from 'lirida-back-service/Servicos/CondicaoPagamento/CondicaoPagamentoService';
import CondicaoPagamento from 'lirida-back-service/Servicos/CondicaoPagamento/CondicaoPagamento';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Constante from '../util/Constante';
import { QRCodeSVG } from 'qrcode.react';
import seloSeguranca from '../assets/imagens/selo-seguranca.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Cobranca from 'lirida-back-service/Servicos/Cobranca/Cobranca';
import Header from '../components/Header';
import Util from '../util/Util';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

export default class CobrancaPage extends Component {
    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    tipoDocumentoService = new TipoDocumentoService(this.constante.token, this.constante.url_base);
    condicaoPagamentoService = new CondicaoPagamentoService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    listaMes = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    listaAno = ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035'];

    state = {
        tela: "2",
        urlBase: '',
        usuario: new Pessoa(),
        financeiro: new Financeiro(),
        condicaoPagamento: new CondicaoPagamento(),
        listaCondicaoPagamento: [],
        cobranca: new Cobranca(),
        carregando: false,
        cartaoVencimentoMes: '',
        cartaoVencimentoAno: '',
        observacaoVisivel: false,
        seqFormaPagamentoCartaoCredito: '',
        seqFormaPagamentoPix: '',
        seqFormaPagamentoBoleto: '',
        mobile: false,
        errorInput: {
            numeroCartao: false,
            mes: false,
            ano: false,
            codigoSeguranca: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    verificarResponsividade() {
        const mediaQuery = window.matchMedia("(max-width: 768px)");

        mediaQuery.addEventListener("change", () => {
            let mobile = false;

            if (mediaQuery.matches) {
                mobile = true;
            }

            console.log("Modo mobile ativo: " + mobile);

            this.setState({ mobile: mobile });

            mediaQuery.removeEventListener('change', this);
        });

        return mediaQuery;
    }

    async iniciar() {
        const retornoMedia = this.verificarResponsividade();

        this.retornoBuscarParametros = this.util.buscarParametros();
        this.urlBase = await this.util.buscarUrlBaseArquivo();

        // if (!this.retornoBuscarParametros.idf) {
        //     alert('Parâmetros inválidos');
        //     window.history.back();
        //     return;
        // }
        
        this.listaPesquisa = [];

        this.pesquisa = new Pesquisa();
        this.pesquisa.campo = 'movimento_financeiro.seq_movimento_financeiro';
        this.pesquisa.conteudo = this.retornoBuscarParametros.id;
        this.pesquisa.operacao = "";
        this.pesquisa.tipo = "TEXTO";
        this.listaPesquisa.push(this.pesquisa);

        this.retornoFinanceiroLista = await this.financeiroService.listarComFiltro(this.listaPesquisa);
        this.financeiro = new Financeiro();
        this.financeiro = this.retornoFinanceiroLista.objeto[0]
        this.retornoFinanceiroBusca = await this.financeiroService.buscarPorSeq(this.financeiro.seqUsuario, this.financeiro._seqMovimentoFinanceiro);
        console.log(this.retornoFinanceiroBusca);

        this.retornoUsuario = await this.util.buscarUsuarioPorSeq(this.retornoFinanceiroBusca.objeto.seqUsuario);
        this.usuario = this.retornoUsuario.objeto;
        console.log(this.usuario)

        this.retornoBuscarTipoDocumento = await this.tipoDocumentoService.buscarPorSeq(this.financeiro.seqUsuario, this.financeiro.seqTipoDocumento);
        this.tipoDocumento = this.retornoBuscarTipoDocumento.objeto;
        console.log(this.tipoDocumento);

        this.retornoCondicaoPagamento = await this.condicaoPagamentoService.listarFormaPagamento(this.tipoDocumento.seqCondicaoPagamento);
        console.log(this.retornoCondicaoPagamento);

        this.listaFormaPagamento = this.retornoCondicaoPagamento.objeto;

        for (this.formaPGTO of this.listaFormaPagamento) {
            if (this.formaPGTO._formaPagamentoOrigem === "2") {
                console.log('cartão', this.formaPGTO.seqFormaPagamento);
                this.setState({ seqFormaPagamentoCartaoCredito: this.formaPGTO.seqFormaPagamento })
            }

            if (this.formaPGTO._formaPagamentoOrigem === "6") {
                this.setState({ seqFormaPagamentoPix: this.formaPGTO.seqFormaPagamento })
            }

            if (this.formaPGTO._formaPagamentoOrigem === "1") {
                this.setState({ seqFormaPagamentoBoleto: this.formaPGTO.seqFormaPagamento })
            }
        }

        console.log("FINANCEIRO vvvvvvvv ", this.retornoFinanceiroBusca.objeto);

        this.financeiro = this.retornoFinanceiroBusca.objeto;

        console.log(this.financeiro);
        this.setState({
            urlBase: this.urlBase,
            usuario: this.usuario,
            financeiro: this.financeiro,
            listaCondicaoPagamento: this.retornoCondicaoPagamento.objeto,
            condicaoPagamento: this.retornoCondicaoPagamento.objeto[0],
            mobile: retornoMedia.matches
        });
    }

    async processar() {
        let errorInput = this.state.errorInput;

        const listaChaveErrorInput = Object.keys(errorInput);

        for (let i = 0; i < listaChaveErrorInput.length; i++) {
            errorInput[listaChaveErrorInput[i]] = false;
        }

        if (this.state.condicaoPagamento.seqFormaPagamento === this.state.seqFormaPagamentoCartaoCredito) {
            if (!this.state.financeiro.cartaoNumero) {
                errorInput.numeroCartao = true;
            }
            if (!this.state.cartaoVencimentoMes) {
                errorInput.mes = true;
            }
            if (!this.state.cartaoVencimentoAno) {
                errorInput.ano = true;
            }
            if (!this.state.financeiro.cartaoDv) {
                errorInput.codigoSeguranca = true;
            }
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveErrorInput.length; i++) {
            if(errorInput[listaChaveErrorInput[i]]) {
                inputOK = false;
            }
        }

        this.setState({errorInput: errorInput});

        if(!inputOK) {
            return;
        }

        this.setState({ carregando: true });
        this.financeiro = this.state.financeiro;

        if (this.state.condicaoPagamento.seqFormaPagamento === this.state.seqFormaPagamentoCartaoCredito) {
            this.financeiro.cartaoVencimento = this.state.cartaoVencimentoMes + '/' + this.state.cartaoVencimentoAno;
        }

        this.financeiro._fpFormaPagamentoOrigem = this.state.condicaoPagamento._formaPagamentoOrigem;
        this.financeiro.seqFormaPagamento = this.state.condicaoPagamento.seqFormaPagamento;

        this.retornoFinanceiro = await this.financeiroService.salvar(this.financeiro);
        this.financeiro = this.retornoFinanceiro.objeto;

        this.retornoFinanceiro = await this.financeiroService.gerarCobranca(this.financeiro);
        this.cobranca = this.retornoFinanceiro.objeto;
        
        console.log(this.cobranca);

        // if (this.state.financeiro.seqFormaPagamento === this.state.seqFormaPagamentoPix) {
        //     this.buscarCobranca(this.cobranca._seqCobranca, "kevin");
        // }

        this.setState({ cobranca: this.cobranca, carregando: false, });
    }

    render() {
        return <div>
            <Header />

            <main style={
                this.state.mobile ?
                    { minHeight: 'calc(100vh - 60px)', backgroundColor: 'white', display: 'flex', flexDirection: 'column' }
                    :
                    { minHeight: 'calc(100vh - 60px)', backgroundColor: 'lightgray', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: 20 }
            }>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        style={
                            this.state.mobile ?
                                { width: 200, height: 200, objectFit: 'contain' }
                                :
                                { width: 400, height: 400, objectFit: 'contain', }
                        }
                        src={this.state.urlBase + this.state.usuario.foto}
                        alt="" />
                </div>

                <div>
                    <div style={
                        this.state.mobile ?
                            { width: '100%', backgroundColor: 'white', padding: 20, }
                            :
                            { width: 400, padding: 20, backgroundColor: 'white', borderRadius: 5, boxShadow: '0 0 5px rgba(0,0,0,0.3)' }
                    }>
                        {!this.state.cobranca._seqCobranca &&
                            <form onSubmit={e => { e.preventDefault(); this.processar() }}>
                                <small>{this.state.usuario.nome}</small><br />
                                <small>CNPJ: {this.state.usuario.cpfResponsavel}</small><br />
                                <small>{this.state.usuario.email}</small><br />
                                <hr></hr>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, marginTop: 10 }}>
                                    <p>Nome</p>
                                    <h3>{this.state.financeiro._pesNome}</h3>

                                    <p>Documento</p>
                                    <h3>{this.state.financeiro._pesDocumento}</h3>

                                    <p>Valor</p>
                                    <h3>{this.util.formatarValor(this.state.financeiro.valorProgramado)}</h3>

                                    <details style={{ marginTop: 10, cursor: 'pointer' }}>
                                        <summary style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Detalhes</summary>
                                        <p style={{ marginTop: 10 }}>{this.state.financeiro.observacao?.split('\n').map(pString => <>{pString}<br /></>) || 'Sem detalhe'}</p>
                                    </details>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 20 }}>
                                    <div>
                                        <label>Forma de pagamento</label><br />
                                        <Dropdown
                                            options={this.state.listaCondicaoPagamento}
                                            optionLabel='_formaPagamento'
                                            onChange={e => this.setState({ condicaoPagamento: e.value })}
                                            value={this.state.condicaoPagamento}
                                            style={{ width: '100%' }} />
                                    </div>
                                    {this.state.condicaoPagamento.seqFormaPagamento === this.state.seqFormaPagamentoCartaoCredito &&
                                        <>
                                            <div>
                                                <label>Número do cartão</label><br />
                                                <InputText
                                                    type="tel"
                                                    onChange={e => {
                                                        this.setState({
                                                            financeiro: {
                                                                ...this.state.financeiro,
                                                                cartaoNumero: e.target.value,
                                                            }
                                                        });
                                                    }}
                                                    value={this.state.financeiro.cartaoNumero}
                                                    style={{ width: '100%', borderColor: this.state.errorInput.numeroCartao && 'var(--red-600)' }} />
                                                    {this.state.errorInput.numeroCartao && <small style={{color: 'var(--red-600)'}}>Número de cartão inválido</small>}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', gap: 5 }}>
                                                <div style={{ width: '50%' }}>
                                                    <label>Mês</label><br />
                                                    <Dropdown
                                                        options={this.listaMes}
                                                        onChange={e => {
                                                            // if (e.target.value.length > 2) return;
                                                            this.setState({ cartaoVencimentoMes: e.value });
                                                        }}
                                                        value={this.state.cartaoVencimentoMes}
                                                        style={{ width: '100%', borderColor: this.state.errorInput.mes && 'var(--red-600)' }} />
                                                        {this.state.errorInput.mes && <small style={{color: 'var(--red-600)'}}>Mês inválido</small>}
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <label>Ano</label><br />
                                                    <Dropdown
                                                        options={this.listaAno}
                                                        onChange={e => this.setState({ cartaoVencimentoAno: e.value })}
                                                        value={this.state.cartaoVencimentoAno}
                                                        style={{ width: '100%', borderColor: this.state.errorInput.ano && 'var(--red-600)' }} />
                                                        {this.state.errorInput.ano && <small style={{color: 'var(--red-600)'}}>Ano inválido</small>}
                                                </div>
                                            </div>
                                            <div>
                                                <label>Código de segurança</label><br />
                                                <InputText
                                                    min={0}
                                                    type="tel"
                                                    onChange={e => {
                                                        if (e.target.value.length > 3) return;
                                                        this.setState({
                                                            financeiro: {
                                                                ...this.state.financeiro,   
                                                                cartaoDv: e.target.value,
                                                            }
                                                        });
                                                    }}
                                                    value={this.state.financeiro.cartaoDv}
                                                    style={{ width: '100%', borderColor: this.state.errorInput.codigoSeguranca && 'var(--red-600)' }} />
                                                    {this.state.errorInput.codigoSeguranca && <small style={{color: 'var(--red-600)'}}>Código de segurança inválido</small>}
                                            </div>
                                        </>
                                    }

                                    <Button
                                        loading={this.state.carregando}
                                        type="submit"
                                        label="Processar"
                                        style={{ width: '100%' }} />

                                    <img
                                        style={{ width: 150, margin: '0 auto' }}
                                        src={seloSeguranca} alt="selo de segurança" />
                                </div>
                            </form>
                        }

                        {this.state.cobranca._seqCobranca && this.state.condicaoPagamento.seqFormaPagamento === this.state.seqFormaPagamentoCartaoCredito &&
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20 }}>
                                {this.state.cobranca.situacao !== "Pagamento Autorizado" &&
                                    <>
                                        <h2>Houve um problema no processamento do seu cartão. Por favor, tente novamente!</h2>
                                    </>
                                }
                                {this.state.cobranca.situacao === "Pagamento Autorizado" &&
                                    <>
                                        <h2>{this.state.cobranca.situacao}</h2>
                                        <Button
                                            onClick={() => window.open("https://crosspag.com.br/comprovante?idf=" + this.state.financeiro._seqMovimentoFinanceiro, "_blank")}
                                            label="Ver comprovante"
                                        />
                                    </>
                                }
                                <Button
                                    onClick={() => this.setState({ tela: "1" })}
                                    label="Voltar"
                                    className='p-button-warning'
                                />
                            </div>
                        }

                        {this.state.cobranca._seqCobranca && this.state.condicaoPagamento.seqFormaPagamento !== this.state.seqFormaPagamentoCartaoCredito &&
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20 }}>
                                {this.state.cobranca.codigoPix && <h4>Aqui está seu código PIX</h4>}
                                {this.state.cobranca.codigoBarras && <h4>Aqui está seu código de barras</h4>}

                                {this.state.cobranca.codigoPix && <QRCodeSVG style={{ margin: '0 auto', }} value={this.state.cobranca.codigoPix} />}

                                {(this.state.cobranca.codigoPix || this.state.cobranca.linhaDigitavel) &&
                                    <div style={{ width: '100%', overflowY: 'scroll', padding: 5, backgroundColor: 'lightgray' }}>
                                        {this.state.cobranca.codigoPix || this.state.cobranca.linhaDigitavel}
                                    </div>
                                }

                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center' }}>
                                    <CopyToClipboard
                                        text={this.state.cobranca.codigoPix || this.state.cobranca.codigoBarras}
                                        onCopy={() => alert('Código pix copiado com sucesso!')}
                                    >
                                        <Button
                                            style={{ width: '100%' }}
                                            label="Copiar código"
                                            onClick={() => window.open(this.state.cobranca.urlBoletoOrigem, "_blank")}
                                            className='p-button-success'
                                        />
                                    </CopyToClipboard>

                                    {this.state.cobranca.linhaDigitavel ?
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                            <Button
                                                onClick={() => this.setState({ cobranca: new Cobranca() })}
                                                label="Voltar"
                                                className='p-button-warning'
                                            />

                                            <Button
                                                label="Visualizar boleto"
                                                onClick={() => window.open(this.state.cobranca.urlBoletoOrigem, "_blank")}
                                                className='p-button-info'
                                            />
                                        </div>
                                        :
                                        <Button
                                            onClick={() => this.setState({ cobranca: new Cobranca() })}
                                            label="Voltar"
                                            className='p-button-warning'
                                        />
                                    }
                                </div>

                                {/* {this.state.cobranca.codigoPix &&
                                    <FormAguardandoPagamento>
                                        <h2>Aguardando seu pagamento</h2>
                                        <Loading style={{ borderLeftColor: tema_hotsite.cor, borderWidth: 3, padding: 10 }} />
                                    </FormAguardandoPagamento>
                                } */}
                            </div>
                        }
                    </div>
                </div>
            </main >
        </div >
    }
}