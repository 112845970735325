import { Component } from "react";
import headerLogo from '../assets/imagens/logo.png';
import imagemBanner from '../assets/imagens/banner.png';
import imagemFlutuante1 from '../assets/imagens/imagem-flutuante1.png';
import imagemFlutuante2 from '../assets/imagens/imagem-flutuante2.png';
import footerLogo from '../assets/imagens/logo.png';
import fotoPessoa from '../assets/imagens/foto-pessoa.png';
import { Sidebar } from 'primereact/sidebar';
import { Accordion, AccordionTab } from 'primereact/accordion';

export default class App extends Component {

  TelaEnum = {
    Principal: "Principal",
    PoliticaPrivacidade: "PoliticaPrivacidade",
    TermoUso: "TermoUso",
  }

  state = {
    sidebarVisivel: false,
    tela: this.TelaEnum.Principal,
  }

  render() {
    return <div>

      <Sidebar visible={this.state.sidebarVisivel} onHide={() => this.setState({ sidebarVisivel: false })}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, }}>
          <a
            onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
            style={{ color: 'black', textDecoration: 'none' }}
            href="#sobre">Sobre</a>
          <a
            onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
            style={{ color: 'black', textDecoration: 'none' }}
            href="#funcionalidades">Funcionalidades</a>
          <a
            onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
            style={{ color: 'black', textDecoration: 'none' }}
            href="#plataformas">Plataformas</a>
          <a
            onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
            style={{ color: 'black', textDecoration: 'none' }}
            href="#perguntas-frequentes">Perguntas frequentes</a>
        </div>
      </Sidebar>

      <header className="h-header-container">
        <img src={headerLogo} alt="logo" />

        <nav>
          <a href="#sobre" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Sobre</a>
          <a href="#funcionalidades" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Funcionalidades</a>
          <a href="#plataformas" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Plataformas</a>
          <a href="#perguntas-frequentes" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Perguntas frequentes</a>

          <button onClick={() => this.setState({ sidebarVisivel: true })}>
            <i className="pi pi-bars"></i>
          </button>
        </nav>
      </header>

      {this.state.tela === this.TelaEnum.Principal &&
        <main className="h-main-container">
          <img className="h-slide-img" src={imagemBanner} alt="imagem principal" />

          <section className="h-sobre-container" style={{ marginTop: "-5px" }} id="sobre">
            <div>
              <i className="pi pi-building" style={{ fontSize: 30 }}></i>
              <h2>Quem somos</h2>
              <p>
                O CrossPag veio para revolucionar a forma de pagamentos convencionais do mercado. Veio para exterminar toda a dificuldade e burocracia dos pagamentos online. Quer receber ou pagar com segurança? CrossPag é a solução certa para você!
              </p>
            </div>
            <div>
              <i className="pi pi-cog" style={{ fontSize: 30 }}></i>
              <h2>Quem somos</h2>
              <p>
                Funciona como o meio de pagamentos online mais prático e mais seguro que você já viu! Precisou receber? O CrossPag recebe! Precisou pagar? Pague pelo CrossPag. Através da geração de links de pagamentos, você pode receber de qualquer lugar e pagar da onde quiser.
              </p>
            </div>
            <div>
              <i className="pi pi-users" style={{ fontSize: 30 }}></i>
              <h2>Quem somos</h2>
              <p>
                Feio para você que tem uma empresa, um aplicativo, uma plataforma ou até um serviço online que precise transacionar dinheiro por ele. O CrossPag faz isso pŕa você através do nosso sistema de integrações com qualquer plataforma do mercado e qualquer conta bancária.
              </p>
            </div>
          </section>

          <section className="h-func-container" id="funcionalidades">
            <div>
              <p>
                A forma mais simples de administrar seu dinheiro
              </p>
              <img src={imagemFlutuante1} alt="imagem flutuante" />
            </div>

            <div>
              <p>
                Controle total sobre gestão de contas e financeiro
              </p>
            </div>

            <div>
              <img src={imagemFlutuante2} alt="imagem flutuante" />
              <p>
                Sem burocracia e com muita segurança
              </p>
            </div>
          </section>

          <section className="h-depo-container" id="Plataformas">
            <h2>Plataformas</h2>

            <div>
              <img src="" alt="" />
              <img src="" alt="" />
              <img src="" alt="" />
              <img src="" alt="" />
              <img src="" alt="" />
            </div>
          </section>

          <section className="h-faq-container" id="perguntas-frequentes">
            <h2 style={{ textAlign: 'center' }}>Perguntas frequentes</h2>

            <Accordion multiple>

              <AccordionTab header="Preciso pagar alguma mensalidade para usar a plataforma?">
                <p>Não, para utilizar nossa plataforma e ter acesso aos recursos é totalmente livre de mensalidade.</p>
              </AccordionTab>

              <AccordionTab header="Depois que criei a conta, posso editar minhas informações?">
                <p>Sim, suas informações ficam disponíveis para edição quando você quiser.</p>
              </AccordionTab>

              <AccordionTab header="Como faço para me cadastrar na plataforma?">
                <p>Entre em contato conosco no email atendimento@crosspag.com.br</p>
              </AccordionTab>

              <AccordionTab header="Eu posso cancelar ou excluir minha conta?">
                <p>Sim, você tem total autonomia e controle sobre sua conta A qualquer momento você exclui sua sem custo nenhum.</p>
              </AccordionTab>

              <AccordionTab header="Preciso passar por alguma análise?">
                <p>:Sim, buscamos levar o melhor atendimento para os clientes, assim para manter a segurança e qualidade dos serviços é preciso que o profissional passe por uma análise.</p>
              </AccordionTab>

            </Accordion>
          </section>
        </main>
      }

      {this.state.tela === this.TelaEnum.PoliticaPrivacidade &&
        <main className="h-main-container" style={{ padding: '90px 30px 30px 30px', }}>
          <section>
            <h1>Política de privacidade</h1><br />

            <p>
              Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível. A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para a Crosspag. Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes que usem a Crosspag serão tratadas em concordância com a Lei da Proteção de Dados Pessoais em vigor. A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel e/ou outros. O uso da Crosspag pressupõe a aceitação deste acordo de privacidade. A equipa da Crosspag reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.
            </p><br />

            <h2>Ligações a sites de terceiros</h2><br />

            <p>
              A Crosspag possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo. Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.
            </p>
          </section>
        </main>
      }

      {this.state.tela === this.TelaEnum.TermoUso &&
        <main className="h-main-container" style={{ padding: '90px 30px 30px 30px' }}>
          <section>
            <h1>Termo de uso</h1><br />
            <p>
              Iniciando o uso do website da Crosspag e dos serviços disponibilizados por ele, você concorda e estará sujeito a todos os termos, políticas e condições gerais descritas abaixo.
            </p><br />

            <h2>1. Objeto</h2><br />

            <p>
              1.1 A Crosspag é uma ferramenta online de captação de recursos financeiro de forma simples e fácil.
            </p><br />
            <p>
              1.2 A Crosspag possibilita a criação da página pública de projetos, possui opção para recebimento de doações e vendas online através de uma aplicação web na internet, acessível a partir do endereço.
              <span><a href='https://www.crosspag.com.br' ref="noreferrer" target='_blank'>https://www.crosspag.com.br</a></span>
            </p><br />

            <h2>2. Propriedade</h2><br />

            <p>
              2.1 A Crosspag foi desenvolvido pela CROSSSYSTEM TECNOLOGIA DA INFORMAÇÃO LTDA. CNPJ 20.706.163./0001-00, e a propriedade do sistema é, e será a qualquer tempo, da Crosspag.
            </p><br />
            <p>
              2.2 O Usuário concorda que o uso da Crosspag não significa, nem significará, aquisição de qualquer direito sobre o mesmo, não podendo explorá-lo comercialmente ou comercializá-lo.
            </p><br />
            <p>
              2.3 O Usuário declara estar ciente de que a Crosspag contém informações confidenciais protegidas pela Lei de Proteção sobre Propriedade Intelectual e por direitos autorais.
            </p><br />

            <h2>3. Serviço</h2><br />
            <p>
              3.1 Para utilização da Crosspag solicitamos os dados de identificação do Usuário para fins legais.
            </p><br />

            <p>
              3.2 A Crosspag é comercializado aos clientes a partir de um modelo de cobrança de tarifas por captação financeira confirmada nos projetos gerenciados. Os valores das tarifas e os prazos são divulgados no site <span><a href='https://www.crosspag.com.br' rel="noreferrer" target='_blank'>https://www.crosspag.com.br</a></span> e podem ser alterados a qualquer momento.
            </p><br />

            <p>
              3.3 É reservado à Crosspag o direito de modificar a Crosspag, acrescentar ou excluir funcionalidades, acrescentar serviços, alterar layout, etc, por qualquer motivo e sem aviso prévio, sem que isso implique qualquer responsabilidade em relação ao Usuário ou a terceiros.
            </p><br />
            <p>
              3.4 Os dados dos Usuários da Crosspag são armazenados em servidores, que seguem políticas e normas de segurança da informação de primeiro nível, realizando cópias de segurança dos dados (backups) periodicamente e preparados para recuperá-los em caso de falhas.
            </p><br />
            <p>
              3.5 A Crosspag também realiza diariamente cópias de segurança dos dados (backups) de todos os Usuários da Crosspag e também está preparada para recuperá-los em caso de falhas. Essa redundância de armazenamento representa nosso segundo nível de segurança dos dados da Crosspag.
            </p><br />
            <p>
              3.6 As informações inseridas na Crosspag são de total responsabilidade do Usuário.
            </p><br />
            <p>
              3.7 O Usuário se compromete a utilizar a Crosspag em conformidade com lei e regulamento local, estadual e nacional.
            </p><br />
            <p>
              3.8 O Usuário se compromete a não utilizar a Crosspag para meios ilícitos ou que prejudique interesse de terceiros, respeitando a ética, a moral e os bons costumes.
            </p><br />

            <p>
              3.9 Para acessar sua conta da Crosspag, o Usuário compromete-se a utilizar uma senha forte (difícil de ser descoberta) e não divulgá-la para outras pessoas, sendo responsável por sua confidencialidade.
            </p><br />

            <p>
              3.10 O Usuário declara expressamente ser responsável por sua conta na Crosspag e aceita voluntariamente que o uso do software ocorra sob sua única e exclusiva responsabilidade.
            </p><br />

            <p>
              3.11 Por ser um portal que permite o cadastramento de informações sobre projetos, a Crosspag analisa e avalia a integridade de cada projeto publicado, podendo ser bloqueado caso seja identificado (i) informações falsas ou errôneas a respeito de projeto divulgadas no Website; (ii) problemas relativos à administração destes projetos, incluindo, mas não se limitando a atrasos, falta de qualidade, disparidades entre o projeto e o anúncio, projetos cadastrados que não correspondam às expectativas dos participantes.
            </p><br />

            <p>
              3.12 Para cancelamento da conta, o proprietário da mesma deverá realizar a solicitação através do email relacionamento@Crosspag.com.br.
            </p><br />

            <p>
              3.13 Para cancelamento de um projeto, a Organização deverá acessar o painel administrativo, com seu usuário e senha, e realizar a inativação do Projeto.
            </p><br />

            <h2>4. Devolução</h2><br />
            <p>
              É possível realizar a devolução do valor doado no prazo máximo de 60 dias, enviado a solicitação para o email relacionamento@Crosspag.com.br.
            </p><br />

            <p>
              4.1 Boleto bancário<br />
              Para receitas realizadas com boleto bancário, o doador deve fornecer os seguintes dados de quem realizou o pagamento:<br /><br />
              - Nome completo;<br />
              - Número do CPF;<br />
              - Nome do banco;<br />
              - Número da conta bancária;<br />
              - Número da agência;<br />
              - Valor a ser devolvido;<br />
              Depois, é só esperar até 10 dias úteis para receber a restituição na sua conta corrente.<br />
            </p><br />

            <p>
              4.2 Cartão de crédito<br />
              para receitas realizadas com cartão de crédito, solicitaremos o estorno do débito à administradora do seu cartão. O prazo para o estorno das parcelas poderá ocorrer em até 2 faturas após o pedido do estorno. Lembre-se que esse procedimento é de responsabilidade da administradora do cartão. Deverá informar os seguintes dados:<br /> <br />
              - Nome completo;<br />
              - Número do CPF;<br />
              - Valor a ser estornado;<br />
            </p><br />
            <h2>5. Garantias e Responsabilidade</h2><br />
            <p>
              5.1 O uso da Crosspag é fornecido sem garantia implícita ou expressa quanto a erros, perda de informações ou qualquer outro evento causado pela má utilização por parte do Usuário.
            </p><br />

            <p>
              5.2 A Crosspag não garante que a Crosspag atenda as necessidades particulares do Usuário.
            </p><br />
            <p>
              5.3 O Usuário declara estar ciente que a Crosspag não tem, nem terá, qualquer responsabilidade, perante ao Usuário ou a terceiros, pelos prejuízos, danos patrimoniais ou morais de qualquer natureza resultante da má utilização da Crosspag.
            </p><br />
            <p>
              5.4 Quando for solicitada transferência bancária de valores pelo usuário, a Crosspag se responsabiliza e se compromete a realizar a mesma na conta informada pelo usuário em até 3 dias úteis, respeitando os valores solicitados e o saldo da conta do usuário, que se refere aos valores recebidos e compensados das receitas em projetos, já descontadas as tarifas cobradas pelo serviço, descritas no item 3.2 deste termo. O prazo de compensação das doações ocorre da seguinte forma:
            </p><br />

            <p>
              5.4.1 Boleto: 1 dia após a notificação de pagamento enviado pelo banco. O Saldo ficará disponível na plataforma para resgate em até 1 dia útil após a compensação;
            </p><br />


            <p>
              5.4.2 Cartão de Crédito: compensação conforme parcelamento (Ex: 30, 60, 90, 120 dias, etc). Saldo ficará disponível na plataforma para resgate em até 1 dia útil após a compensação;
            </p><br />

            <h2>6. Foro</h2><br />
            <p>
              6.1 As Partes desenvolverão todos os esforços no sentido de resolver, amigavelmente, todas as controvérsias ou divergências que, porventura, forem decorrentes deste Termo.
            </p><br />
            <p>
              6.2 Não obstante o disposto no item anterior, o Usuário, ao aceitar este Termo, concorda em eleger o foro da comarca do Rio de Janeiro, no Estado do Rio de Janeiro, como competente para dirimir qualquer dúvida ou controvérsia porventura oriunda deste Termo e/ou da utilização do Sistema e, expressamente, renuncia a qualquer outro.
            </p><br />

            <h2>7 . Disposições Finais</h2><br />
            <p>
              7.1 O Usuário concorda em receber, via e-mail, informativos do Crosspag e da Crosspag.
            </p><br />

            <p>
              7.2 Os termos e condições poderão ser atualizados e modificados a qualquer tempo, mediante notificação prévia. Estando o Usuário de acordo, será feito um termo aditivo de comum acordo entre as partes.
            </p><br />
            <p>
              7.3 O Usuário aceita que a Crosspag poderá, ao seu critério, alterar a prestação de serviços da Crosspag, interromper temporária ou definitivamente, mediante aviso prévio ao Usuário.
            </p><br />
            <p>
              7.4 A violação de qualquer condição estabelecida neste termo ensejará a imediata exclusão do Usuário do cadastro da Crosspag, ficando a partir de então suspenso qualquer acesso do Usuário às áreas restritas do Website, sem prejuízo do direito da Crosspag e dos terceiros prejudicados de exigirem a reparação dos danos eventualmente causados independentemente de prévia notificação formal.
            </p><br />

            <p>
              7.5 O Usuário será o único, direto e exclusivo responsável por todos os danos, infrações, descumprimento legais e atos de qualquer natureza, que venha a causar a terceiros ou outros quando da utilização do sistema objeto deste instrumento. Na hipótese da Crosspag vir a ser demandada em juízo por dano ocasionado pelo Usuário, ser-lhe-á lícito alegar sua ilegitimidade passiva, nomeá-lo à autoria, denunciá-lo à lide etc., enfim, praticar todos os atos necessários para exercer seu direito de regresso, servindo o presente instrumento como título executivo extrajudicial de todos e quaisquer valores eventualmente despendidos pela Crosspag para fazer frente a indenizações, despesas judiciais etc., sendo que estes deverão ser atualizados na forma da lei.
            </p><br />

            <p>
              7.6 O Usuário declara que leu e concordou com todos os termos acima citados.
            </p><br />
          </section>
        </main>
      }

      <footer className="h-footer-container">
        <section>
          <div>
            <a href="#sobre" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Sobre</a>
            <a href="#funcionalidades" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Funcionalidades</a>
            <a href="#plataformas" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Plataformas</a>
            <a href="#perguntas-frequentes" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Perguntas frequentes</a>
          </div>
          <div>
            <a href="#" onClick={e => { e.preventDefault(); this.setState({ tela: this.TelaEnum.PoliticaPrivacidade }); window.scrollTo(0, 0) }}>Política de privacidade</a>
            <a href="#" onClick={e => { e.preventDefault(); this.setState({ tela: this.TelaEnum.TermoUso }); window.scrollTo(0, 0) }}>Termos de uso</a>
          </div>
          <div>
            <a href="#" onClick={e => { e.preventDefault(); window.open("https://www.facebook.com/pagcross") }}>Facebook</a>
            <a href="#" onClick={e => { e.preventDefault(); window.open("https://www.instagram.com/pagcross") }}>Instagram</a>
          </div>
        </section>

        <img src={footerLogo} alt="logo rodapé" />
      </footer>
    </div>
  }
}