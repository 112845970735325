import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Hotsite from './pages/Hotsite';
import reportWebVitals from './reportWebVitals';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import CobrancaPg from './pages/CobrancaPg';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<Hotsite />} />
            <Route path="/cobranca" element={<CobrancaPg />} />
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
